export const FETCH_ESSAY_REQUEST = 'FETCH_ESSAY_REQUEST';
export const FETCH_ESSAY_SUCCESS = 'FETCH_ESSAY_SUCCESS';
export const FETCH_ESSAY_FAILURE = 'FETCH_ESSAY_FAILURE';
export const CREATE_ESSAY_REQUEST = 'CREATE_ESSAY_REQUEST';
export const CREATE_ESSAY_SUCCESS = 'CREATE_ESSAY_SUCCESS';
export const CREATE_ESSAY_FAILURE = 'CREATE_ESSAY_FAILURE';
export const UPDATE_ESSAY_REQUEST = 'UPDATE_ESSAY_REQUEST';
export const UPDATE_ESSAY_SUCCESS = 'UPDATE_ESSAY_SUCCESS';
export const UPDATE_ESSAY_FAILURE = 'UPDATE_ESSAY_FAILURE';
export const UPDATE_CHECK_ESSAY_REQUEST = 'UPDATE_CHECK_ESSAY_REQUEST';
export const CLEAR_CURRENT_ESSAY = 'CLEAR_CURRENT_ESSAY';
